<template>
  <div class="rotors" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Rotors"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('rotors-title')"></h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('rotors-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('rotors-section1-paragraph')"
      ></div>
    </div>

    <div class="zoom-image-container" id="race_car">
      <img :src="images[1]" alt="CarboBrake Race Car"/>
    </div>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('rotors-section2-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('rotors-section2-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[2]" alt="CarboBrake Cooling"/>
      </div>
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('rotors-section3-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('rotors-section3-paragraph')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/rotors_cover.jpg'),
        require('../assets/race-car.jpg'),
        require('../assets/sicom_AF_step1.jpg')
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)

        //animazioni della pagina
        //SCROLLMAGIC
        // Declare Scene
        const sceneRace = this.$scrollmagic
          .scene({
            // ID of element where animation starts
            triggerElement: '#race_car',

            // {0,0.5,1} - animations starts from {top,center,end} of window
            triggerHook: 0,

            offset: 0
          })

          // Declaration of animation and attaching to element
          .setTween('#race_car img', 0.8, {
            scale: 1
          })
        // Helpful tags for orientation on the screen
        //.addIndicators({ name: 'cooling (duration: 300)' })

        // add scenes to window scrollmagic controller
        this.$scrollmagic.addScene(sceneRace)
      })
    })
  }
}
</script>

<style lang="scss"></style>
